import "../scss/plugins/_swiper.scss";

import Swiper from "swiper";
import { Navigation, Pagination, EffectFade, Autoplay, A11y, Controller } from "swiper/modules";

const swiperSlideSpeed = 750;

const sliderGalleries = document.querySelectorAll("[data-swiper-gallery]");
if (sliderGalleries.length) {
  sliderGalleries.forEach((gallery) => {
    const slidesPerView = Number(gallery.getAttribute("data-slides-per-view"));
    let slidesPerViewTablet = 1;
    if (slidesPerView >= 3) {
      slidesPerViewTablet = Math.round(slidesPerView / 2);
    }
    const slidesPerViewMobile = slidesPerView > 4 ? 2 : 1;
    const sliderGallery = new Swiper(gallery, {
      modules: [Navigation, Pagination, A11y],
      slidesPerView: slidesPerViewMobile,
      spaceBetween: 10,
      autoHeight: true,
      speed: swiperSlideSpeed,
      pagination: {
        el: "[data-swiper-pagination]",
        type: "fraction",
      },
      navigation: {
        nextEl: "[data-swiper-button-next]",
        prevEl: "[data-swiper-button-prev]",
      },
      breakpoints: {
        667: {
          slidesPerView: slidesPerViewTablet,
        },
        992: {
          slidesPerView: slidesPerView,
        },
      },
    });
  });
}

const swiperHero = new Swiper("[data-swiper-hero]", {
  modules: [Navigation, Pagination, EffectFade, Autoplay, A11y],
  slidesPerView: 1,
  spaceBetween: 0,
  loop: true,
  speed: swiperSlideSpeed,
  autoplay: {
    delay: 3000,
  },
  effect: "fade",
  navigation: {
    nextEl: "[data-swiper-button-next]",
    prevEl: "[data-swiper-button-prev]",
  },
  pagination: {
    el: "[data-swiper-pagination]",
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"><svg class="swiper-autoplay-progress" viewBox="0 0 48 48"><circle cx="24" cy="24" r="20"></circle></svg></span>';
    },
  },
  on: {
    slideChange: function (swiper) {
      const bullets = swiper.el.querySelectorAll(".swiper-pagination-bullet .swiper-autoplay-progress");
      bullets.forEach((bullet) => {
        bullet.style.setProperty("--progress", 0);
      });
    },
    autoplayTimeLeft(swiper, time, progress) {
      swiper.el.querySelector(".swiper-pagination-bullet-active .swiper-autoplay-progress").style.setProperty("--progress", 1 - progress);
    },
  },
});

const swiperTestimonials = new Swiper("[data-swiper-testimonials]", {
  modules: [Navigation, Pagination, A11y, Controller],
  slidesPerView: 1,
  spaceBetween: 20,
  loop: true,
  speed: swiperSlideSpeed,
  navigation: {
    nextEl: "[data-swiper-button-next]",
    prevEl: "[data-swiper-button-prev]",
  },
  pagination: {
    el: "[data-swiper-pagination]",
    type: "fraction",
  },
});

const swiperTestimonialsGallery = new Swiper("[data-swiper-testimonials-gallery]", {
  modules: [EffectFade, A11y],
  slidesPerView: 1,
  speed: swiperSlideSpeed,
  loop: true,
  spaceBetween: 0,
  allowTouchMove: false,
  effect: "fade",
});

swiperTestimonials.controller.control = swiperTestimonialsGallery;

const swiperPosts = new Swiper("[data-swiper-posts]", {
  modules: [Navigation, Pagination, A11y],
  slidesPerView: 1,
  spaceBetween: 0,
  loop: false,
  speed: swiperSlideSpeed,
  navigation: {
    nextEl: "[data-swiper-button-next]",
    prevEl: "[data-swiper-button-prev]",
  },
  pagination: {
    el: "[data-swiper-pagination]",
    type: "fraction",
  },
  breakpoints: {
    667: {
      slidesPerView: 2,
      slidesPerGroup: 2,
    },
    1080: {
      slidesPerView: 4,
      slidesPerGroup: 4,
    },
  },
});
